html::-webkit-scrollbar {
    display: none;
}

.materielInput {
  display: inline-flex !important;
  width: 33% !important;
}

.imageMateriel {
  max-height: 55px;
  max-width: 100px;
}

.specialPopUp {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  top: 100px !important;
  z-index: 100 !important;
}

@font-face {
  font-family: "sport";
  src: url('/sport.ttf');
}
.placeholder-ul{
  visibility: hidden;
}

.placeholder-li:hover {
  background: #F1F1F1;
}

.header {
  background-color: cornflowerblue;
  height: 70px;
  margin-top: 0px;
  text-align: center;
  color: white;
}

.btnDeco {
  border: 1px solid lightgrey;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  background-color: transparent;
}

.editorClassName {
  border: 1px solid lightgrey;
  border-radius: 5px;
  min-height: 250px;
}

.headerRight {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
}

button:focus {
  outline: unset !important;
}

.title-header {
  margin: 0 auto;
  line-height: 70px;
  font-size: 45px;
  font-family: "sport";
}

.title {
  text-align: center;
  font-family: "sport";
  font-size: 30px;
}

.cycleTitle {
  text-transform: uppercase;
  font-size: 20px;
  display: inline-block;
  color: #DE772D;
}

.cycleNum {
  font-size: 40px;
  display: inline-block;
  font-weight: bold;
  margin-top: -10px;
  color: #DE772D;
}

.form-group {
  margin: 0 auto;
  padding: 20px;
  border: 1px solid cornflowerblue;
  border-radius: 15px;
}

.vertical-center {
  margin: 0;
  position: absolute !important;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.goalImg {
  width: 50px;
  height: 35px;
  background: no-repeat url('/goal.png');
  background-size: contain;
  margin-left: 10px;
  margin-right: 10px;
}

.maintenanceImg {
  width: 35px;
  height: 35px;
  background: no-repeat url('/maintenance.png');
  background-size: cover;
  margin-left: 10px;
  margin-right: 10px;
  position: absolute;
}

.contentDivVariable {
  padding-top: 20px;
}

.allVariable {
  padding-top: 20px;
  padding-bottom: 20px;
}

.displaySeanceHeader {
  width: 100% !important;
  padding-left: 20px !important;
  padding-bottom: 10px !important;
  background: linear-gradient(to bottom right, #4CA9B3, #48A1C0);
  height: auto;
  display: flex;
  align-items: center;
}

.displaySeanceHeaderMateriel {
  width: 100% !important;
  padding-left: 20px !important;
  padding-bottom: 10px !important;
  background: linear-gradient(to bottom right, #4CA9B3, #48A1C0);
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.topHeaderMateriel {
  width: 100%;
  margin-bottom: 30px;
  position: relative;
  margin-left: 20px;
}

.topHeaderOrganisation {
    width: 100%;
    position: relative;
    margin-left: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.materielTitle {
  font-size: 35px;
  position: absolute;
  bottom: 0 !important;
  margin-left: 10px;
  color: #4CA9B3;
  line-height: 28px;
}

.materielTitleImg {
  width: 40px;
  height: 40px;
}

.displaySeanceBody {
  width: 100% !important;
  padding: 0px !important;
  margin: 0px !important;
}

.centerText {
  text-align: center;
}

.imgModalWidth {
  width: inherit;
  padding-left: 10px;
  padding-right: 10px;
}

.bottomBar {
  padding-top: 40px;
  height: auto;
}

.contentHeight {
  height: 60px;
  border-top: 1px solid lightgrey;
}

.btnBottom1 {
  width: 35px !important;
  height: 35px;
  width: auto;
}

.btnBottom2 {
  width: 35px !important;
  height: 35px;
  width: auto;
}

.btnBottom2 {
  width: 25px !important;
  height: 25px;
  width: auto;
}

.btnBottom3 {
  width: 35px !important;
  height: 35px;
  text-align: center;
  width: auto;
  -ms-transform: translateY(-20%);
  transform: translateY(20%);
}

.btnBottom4 {
  width: 35px !important;
  height: 35px;
  width: auto;
}

.btnBottom5 {
  width: 35px !important;
  height: 35px;
  width: auto;
}

.noPadding3 {
  padding: 0px;
  padding-top: 8px;
  cursor: pointer;
  text-align: center;
}

.materielPicto {
  cursor: pointer;
  text-align: center;
  margin-top: -25px;
}

.fullWidth {
  width: 100%;
  position: absolute;
}

.textArea1 {
  min-height: 80px;
}

.textArea2 {
  min-height: 120px;
}

.homeBtn {
  text-align: center;
  -ms-transform: translateY(-50%);
  transform: translateY(-62%);
  background: coral;
  border-radius: 50%;
  height: 55px;
  width: 55px;
  margin: 0 auto;
}

.croquis {
  background: no-repeat url('/organisation.jpg');
  background-size: cover;
  height: 260px;
}

.materiel {
  background: no-repeat url('/materiel.png');
  background-size: cover;
  height: 260px;
}

.consigneNumber {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid black;
  font-size: 25px;
}

.consigneNumberLine {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.divVariable {
  border: 1px solid #4CA9B3;
  margin: auto;
  width: 70%;
  height: auto;
  border-radius: 20px;
}

.textTitleVariable {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
  width: 100%;
}

.textSeanceHeader {
  color: white;
  font-weight: bold;
  margin: 0px !important;
}

.noPadding {
  padding: 0px !important;
  margin: 0px !important;
}

.btnCycleSelected {
  border-radius: 5px;
  background-color: white;
  border-color: lightgrey;
  margin-right: 10px;
  border: 5px solid lightgreen;
  width: 80px;
  height: 80px;
}

.btnCycle {
  border-radius: 5px;
  background-color: white;
  border-color: lightgrey;
  margin-right: 10px;
  border: 1px solid lightgrey;
  width: 80px;
  height: 80px;
}

.allCycle {
  display: inline-flex;
  margin-top: 20px;
}

.icon-competence {
  width: 65px;
}

.contentIcon {
  margin-bottom: 15px;
}

.cardCompetence {
  background-color: #c1cd32;
  height: 150px;
  width: 120px;
  border-radius: 10px;
  font-size: 9px;
  text-transform: uppercase;
  border: 5px solid #c1cd32;
  margin-right: 10px;
}

.cardCompetenceSelected {
  background-color: #c1cd32;
  height: 150px;
  width: 120px;
  border-radius: 10px;
  font-size: 9px;
  text-transform: uppercase;
  border: 5px solid lightgreen;
  margin-right: 10px;
}

.cardSequence {
  height: 150px;
  width: 250px;
  border-radius: 10px;
  border: 5px solid transparent;
  background-color: transparent;
  color: white;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  position: relative;
}

.cardSequenceSelected {
  height: 150px;
  width: 250px;
  border-radius: 10px;
  border: 5px solid lightgreen;
  background-color: transparent;
  color: white;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  position: relative;
}

.cardCategorie {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 5px solid lightgrey;
}

.cardCategorieSelected {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 5px solid lightgreen;
}

.buttonCategorie {
  display: block !important;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  width: 100px;
  margin-right: 20px !important;
}

.titleHeader {
  text-align: center;
}

.textSequence {
  position: absolute;
  left: 0;
  bottom: 0;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.textTitleHeader {
  font-size: 22px;
  font-weight: bold;
}

.textTitleHeader2 {
  font-size: 22px;
  font-weight: bold;
  line-height: 100px;
  color: white;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.btn-info {
  background-color: cornflowerblue !important;
}

.form-control {
  margin-bottom: 15px;
}

.create {
  margin-top: 20px;
  font-size: 20px !important;
  font-weight: bold !important;
  background-color: cornflowerblue !important;
  border: none !important;
}

.faicon {
  padding-right: 20px;
}

.form-container {
  display: inline-block;
}

.btnDeleteImg {
  top: -9px;
  right: -9px;
  color: #fff;
  background: #ff4081;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  line-height: 0px;
  padding-left: 6px;  
  width: 30px;
  height: 30px;
  position: absolute;
}

.btnDeleteImg2 {
  margin-left: 20px;
  color: #fff;
  background: #ff4081;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  line-height: 0px;
  padding-left: 6px;  
  width: 30px;
  height: 30px;
  position: absolute;
}

.modalTitleCenter {
  text-align: center;
  display: initial !important;
}

.hide {
  display: none
}

.sous-title {
  font-size: 17px !important;
}

.image-upload {
  width: 100% !important;
  background-color: cornflowerblue !important;
  border: none !important;
  border-radius: 5px !important;
  margin: 0px !important;
  text-align: left !important;
  height: calc(1.5em + .75rem + 2px);
  font-size: 1rem !important;
}

.displayBlock {
  display: block;
}

.displayNone {
  display: none;
}

.span-text {
  font-family: inherit;
  font-size: large;
}

.textareaHeight {
  min-height: 250px;
}

.labelCheckbox {
  padding-left: 15px;
  font-size: 16px;
  color: grey;
}

.content-checkbox {
  margin: 0px !important;
}

.card-style {
  padding: 0px !important;
}

.cardActivite {
  border: none;
  padding: 0px;
  margin: 0px;
  background: linear-gradient(to bottom right, #4CA9B3, #48A1C0);
  cursor: pointer;
  width: inherit !important;
  z-index: 1;
  width: 360px;
}

.cardActivite2 {
  border: none;
  padding: none;
  margin: none;
  background: linear-gradient(to bottom right, #4499A1, #4091AB);
  cursor: pointer;
  width: inherit !important;
  z-index: 1;
}

.firstCard {
  margin: 0px !important;
  padding: 0px !important;
  height: 50px;
  background-color: #FE7F9C;
}

.firstCard1 {
  margin: 0px !important;
  padding: 0px !important;
  height: 50px;
  background-color: white;
  border-radius: 0px 0px 0px 60px;
}

.topCard1 {
  margin: 0px !important;
  padding: 0px !important;
  height: 50px;
  background-color: #FE7F9C;
}

.topCard11 {
  margin: 0px !important;
  padding: 0px !important;
  height: 50px;
  background-color: #9932CC;
  border-radius: 0px 0px 0px 60px;
}

.topCard2 {
  margin: 0px !important;
  padding: 0px !important;
  height: 50px;
  background-color: #9932CC;
}

.topCard21 {
  margin: 0px !important;
  padding: 0px !important;
  height: 50px;
  background-color: #FE7F9C;
  border-radius: 0px 0px 0px 60px;
}

.row {
  margin: 0px !important;
}

.displaySequence {
  margin-right: 10px !important;
}

.photoTitle {
  width: inherit !important;
}

.info-div {
  background-color: grey;
  color: white;
  padding: 10px;
  padding-left: 30px;
}

.info-div p {
  margin: 0;
}

.activiteCard {
  display: inline-flex;
  margin-top: 15px;
  padding-bottom: 20px !important;
  width: 100%;
}

.noMargin {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.noPadding2 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.bodyFlex {
  display: flex !important;
}

.flexDiv {
  display: inline-flex;
}

.titleAtelier {
  padding: 10px;
  margin-left: -15px;
  display: inline-flex;
}

.objectifActivite {
  color: white;
  font-size: 13px;
}

.titleBubble {
  text-align: center;
  font-size: 9px;
  padding-top: 11px;
  display: block;
  text-transform: uppercase;
  margin-bottom: -6px;
  color: #3E8FCC;
}

.numBubble {
  font-size: 20px;
  display: block;
  text-align: center;
  font-weight: bold;
  color: #3E8FCC;
}

.marginAuto {
  margin: 0 auto;
  z-index: 100;
}

.titleActivityBubble {
  width: 270px;
}

.titleActivityBubble1 {
  width: 270px;
  padding-top: 5px;
  font-weight: bold;
  color: white;
  font-size: 22px;
  margin: 0px;
  text-transform: uppercase;
}

.titleActivityBubble2 {
  width: 270px;
  margin: 0px;
  font-size: 13px;
}

.whiteText {
  color: white;
}

.labelActivite {
  margin: 0px !important;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
}

.contentAtelier {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.drawLine {
  width:30px;
  height: 1px;
  border-bottom: 1px solid white;
  margin-top:5px !important;
  margin-bottom:5px !important;
}

.resume-seance {
  white-space: pre-wrap;
  padding: 0px !important;
}

.resume-title {
  text-decoration: underline;
  padding-bottom: 20px;
}

.border-deco {
  background-color: lightblue;
}

.modification {
  margin-left: 5px;
  z-index: 10;
}

.btn-custom {
  border: 1px solid lightgrey !important;
  border-radius: 25px !important;
  box-shadow: 9px 7px 12px 6px #EEEEEE;
  margin: 0 auto !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 10px !important;
}

.btn-custom:hover {
  background-color: #EEEEEE !important;
}

.visible {
  display: block !important;
}

.hidden {
  display: none !important;
}

.inputFile {
  height: auto !important;
}

.content-seance {
  min-height: 500px;
}

.btn-in-modal {
  margin-right: 5px;
  margin-bottom: 20px;
}